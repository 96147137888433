import {Link} from "react-router-dom";

function PageName({title, subTitle}) {
    return(
        <div id="breadcrumb" className="division text-left">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className=" breadcrumb-holder">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{subTitle}</li>
                                </ol>
                            </nav>
                            <h4 className="h4-sm steelblue-color">{title}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PageName;