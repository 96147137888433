import React, {useEffect} from "react";
import PageName from "../../component/common/page-name/page-name";

function WorkWithUs() {
    const scrollTop = async () => {
        await window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, []);
    return (
        <>
            <PageName title="Work & Volunteer with us" subTitle="Work & Volunteer with us"/>
            <section id="info-1" className="wide-60 info-section division text-left">
                <div className="container">
                    <div className="row d-flex align-items-center">

                        <div className="col-lg-6">
                            <div className="info-1-img text-center mb-40 wow fadeInUp" data-wow-delay="0.6s">
                                <img className="img-fluid"  src={require('../../images/work-with-us.JPG')} alt="work-with-us"/>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="txt-block pc-30 mb-40 wow fadeInUp" data-wow-delay="0.4s">

                                <span className="section-id blue-color">Work & Volunteer with us </span>


                                <p className="mb-30 text-justify">Garkuwa Specialist Hospital is more than just a
                                    workplace. We help patients with complex health problems by using our knowledge
                                    advantage – which also benefits employees. We are the last hope for some patients
                                    and the first choice for many employees.
                                </p>

                                <p className="mb-30 text-justify">Find out more about open positions, experience the
                                    diversity of the GSH and discover an ideal environment to learn from the best and
                                    apply expertise.
                                </p>

                                <p className="mb-30 text-justify">Garkuwa Specialist Hospital also offers
                                    apprenticeships and internships, providing interesting entry-level opportunities for
                                    careers in this field. GSH employees profit from numerous benefits.
                                </p>

                                <div className="mb-4"><h6>Our wards are clean and quite, well equipped with the
                                    following:</h6></div>
                                <div className="row">

                                    <div className="col-xl-6">

                                        <div className="box-list m-top-15">
                                            <div className="box-list-icon blue-color"><i
                                                className="fas fa-angle-double-right"></i></div>
                                            <p className="p-sm">WIFi Available</p>
                                        </div>
                                        <div className="box-list">
                                            <div className="box-list-icon blue-color"><i
                                                className="fas fa-angle-double-right"></i></div>
                                            <p className="p-sm">Desktop Computer and Satellite TV</p>
                                        </div>

                                    </div>

                                    <div className="col-xl-6">
                                        <div className="box-list">
                                            <div className="box-list-icon blue-color"><i
                                                className="fas fa-angle-double-right"></i></div>
                                            <p className="p-sm">Calm and Conducive</p>
                                        </div>
                                        <div className="box-list">
                                            <div className="box-list-icon blue-color"><i
                                                className="fas fa-angle-double-right"></i></div>
                                            <p className="p-sm">Constant Nurse Monitoring, Patient Calling System</p>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </section>

            <section id="services-3" className="bg-lightgrey wide-100 services-section division">
                <div className="container">


                    <div className="row text-center">
                        <div className="col-md-6 ">
                            <div className="card">
                                <div className="card-body" style={{height: '300px'}}>
                                    <div><span className="fa fa-eye fa-4x text-info mb-3"></span></div>
                                    <div className="card-title mb-4"><h3 className="">Our Mission</h3></div>
                                    <p>The mission of <b>Garkuwa Specialist Hospital</b> is to provide quality health
                                        services
                                        and facilities for the community, to promote wellness and to restore health as
                                        safely and humanely as it can be done, consistent with global best practice, and
                                        demonstrating social responsibility through the just use of our resources.
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6" style={{height: '300px'}}>
                            <div className="card">
                                <div className="card-body" style={{height: '300px'}}>
                                    <div><span className="fa fa-search fa-4x text-info mb-3"></span></div>
                                    <div className="card-title mb-4"><h3 className="">Our Vission</h3></div>
                                    <p><b>Garkuwa Specialist Hospital</b> will be an innovative, leading health system
                                        dedicated to providing quality health care services at affordable rates.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default WorkWithUs;