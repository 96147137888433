import React, {useEffect} from "react";
import PageName from "../../component/common/page-name/page-name";
import {Link} from "react-router-dom";

function WhoWeAre() {
    const scrollTop = async () => {
        await window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, []);
    return (
        <>
            <PageName title="Who We Are" subTitle="Who We Are"/>
            <section id="info-1" className="wide-60 info-section division text-left">
                <div className="container">
                    <div className="row d-flex align-items-center">


                        <div className="col-lg-6">
                            <div className="info-1-img text-center mb-40 wow fadeInUp" data-wow-delay="0.6s">
                                <img className="img-fluid" src={require('../../images/who-we-are.JPG')} alt="who-we-are"/>
                            </div>
                        </div>


                        <div className="col-lg-6">
                            <div className="txt-block pc-30 mb-40 wow fadeInUp" data-wow-delay="0.4s">
                                <span className="section-id blue-color">Welcome to Garkuwa Specialist Hospital </span>

                                <h3 className="h3-md steelblue-color">Clinic with Innovative Approach to Treatment</h3>

                                <p className="mb-30 text-justify">GARKUWA Specialist Hospital is a private health facility with the
                                    goal of providing comprehensive and affordable quality medical services to all and
                                    to develop a strong base of clients, to build a good reputation and become a key
                                    player in the industry.
                                </p>

                                <p className="mb-30 text-justify">In addition to the provision of basic Primary Health services The
                                    Hospital presently has staff strength of almost 100 permanent staff and fifteen
                                    visiting specialists providing services on a twenty four hour basis.
                                </p>

                                <p className="mb-30 text-justify">Medical and Nursing services are provided by a team of five
                                    Resident Medical Officers and over thirty qualified Nurses and Midwives supported by
                                    Consultants in the stated specialties with specialist clinics conducted at various
                                    times throughout the week. Our work is guided by key values; Diligence, Honesty
                                    ,integrity, Ethics and Morality.
                                </p>

                                <div className="mb-4"><h6>Our wards are clean and quite, well equipped with the following:</h6></div>
                                <div className="row">

                                    <div className="col-xl-6">

                                        <div className="box-list m-top-15">
                                            <div className="box-list-icon blue-color"><i
                                                className="fas fa-angle-double-right"></i></div>
                                            <p className="p-sm">WIFi Available</p>
                                        </div>
                                        <div className="box-list">
                                            <div className="box-list-icon blue-color"><i
                                                className="fas fa-angle-double-right"></i></div>
                                            <p className="p-sm">Desktop Computer and Satellite TV</p>
                                        </div>

                                    </div>

                                    <div className="col-xl-6">
                                        <div className="box-list">
                                            <div className="box-list-icon blue-color"><i
                                                className="fas fa-angle-double-right"></i></div>
                                            <p className="p-sm">Calm and Conducive</p>
                                        </div>
                                        <div className="box-list">
                                            <div className="box-list-icon blue-color"><i
                                                className="fas fa-angle-double-right"></i></div>
                                            <p className="p-sm">Constant Nurse Monitoring, Patient Calling System</p>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </section>

            <section id="services-3" className="bg-lightgrey wide-100 services-section division">
                <div className="container">


                    <div className="row text-center">
                        <div className="col-md-6 ">
                            <div className="card">
                                <div className="card-body" style={{height: '300px'}}>
                                    <div><span className="fa fa-eye fa-4x text-info mb-3"></span></div>
                                    <div className="card-title mb-4"><h3 className="">Our Mission</h3></div>
                                    <p>The mission of <b>Garkuwa Specialist Hospital</b> is to provide quality health services
                                        and facilities for the community, to promote wellness and to restore health as
                                        safely and humanely as it can be done, consistent with global best practice, and
                                        demonstrating social responsibility through the just use of our resources.
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6" style={{height: '300px'}}>
                            <div className="card">
                                <div className="card-body" style={{height: '300px'}}>
                                    <div><span className="fa fa-search fa-4x text-info mb-3"></span></div>
                                    <div className="card-title mb-4"><h3 className="">Our Vission</h3></div>
                                    <p><b>Garkuwa Specialist Hospital</b> will be an innovative, leading health system
                                        dedicated to providing quality health care services at affordable rates.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default WhoWeAre;