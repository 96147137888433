import React, {useEffect, useState} from "react";
import PageName from "../../component/common/page-name/page-name";
import {setServiceDetails} from "../../actions/setactiondetails";
import {connect} from "react-redux";
import PageLoader from "../../component/common/loader/page-loader";
import {showAlert} from "../../component/common/sweetalert/sweetalert";
import axios from "axios";
import {serverLink, getDayName, encryptData, paymentAPIKey, generate_token} from "../../resources/url";
import Select from "react-select";
import {toast} from "react-toastify";
import {usePaystackPayment} from "react-paystack";

function Appointment(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [IsGettingSchedules, setIsGettingSchedules] = useState(false)
    const [serviceTypeList, setServiceTypeList] = useState([])
    const [serviceList, setServiceList] = useState([])
    const [doctorSchedules, setDoctorSchedules] = useState([])
    const [doctorsList, setDoctorsList] = useState([])
    const [specialisationList, setSpecialisationList] = useState([])
    const [doctor, setDoctor] = useState([])
    const [serviceType, setServiceType] = useState([])
    const [services, setServices] = useState([])
    const [specialization, setSpecialization] = useState([])
    const [scheduleSlots, setScheduleSlots] = useState([])

    const appointmentInitial = {
        patientSerial: '',
        patientName: '',
        doctorEmail: "",
        doctorName: "",
        doctorID: "",
        doctorID2: "",
        serviceID: "",
        serviceID2: "",
        consultationTypeID: "",
        consultationTypeID2: "",
        itemName: "",
        appointmentDepartment: "",
        appointmentDepartmentName: "",
        appointmentDepartment2: "",
        appointmentDetail: "",
        appointmentDate: "",
        appointmentTime: "",
        slotID: "",
        paymentOption: "",
        appointmentAmount: 0,
        walletAmount: 0,
        firstName: "",
        middleName: "",
        surname: "",
        emailAddress: "",
        phoneNumber: "",
        password: encryptData('123456789'),
        submittedByName: '',
        submittedBy: '',
    }
    const [formData, setFormData] = useState(appointmentInitial)
    const resetFormData = () => {
        setFormData(appointmentInitial)
    }

    const config = {
        reference: "TRANX"+generate_token(10),
        email: formData.emailAddress,
        amount: formData.appointmentAmount  * 100,
        publicKey: paymentAPIKey,
        currency: 'NGN',
    };


    useEffect(() => {
        getData().then(()=>{});
        scrollTop().then(()=>{});
    }, []);

    const getData = async () => {
        await axios.get(`${serverLink}patient-portal/appointment/data`)
            .then((result) => {
                let specialisation = []; let doctors = []; let service_types = []; let service_list = [];
                result.data.specialisations.map((sp) => {
                    specialisation.push({value: sp.specialisationID, label: sp.specialisationName})
                })

                result.data.doctors.map((row) => {
                    doctors.push({value: row.userID, label: row.doctorName +' => ('+ row.userID+')', phone_number: row.phoneNumber, email_address: row.emailAddress, specialisation: row.specialisationID})
                });

                result.data.serviceTypes.map((row) => {
                    service_types.push({value: row.serviceID, label: row.serviceName, service_description: row.serviceDescription})
                });

                result.data.services.map((row) => {
                    service_list.push({value: row.serviceDataID, label: row.serviceItemName, service_id: row.serviceID, amount: row.serviceAmount, description: row.serviceDescription})
                });

                setDoctorsList(doctors);
                setSpecialisationList(specialisation);
                setSpecialization(specialisation);
                setServiceTypeList(service_types);
                setServiceType(service_types);
                setServiceList(service_list);

                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    const onEdit = async (e) => {
        if (e.target.id === 'appointmentDate'){
            setIsGettingSchedules(true)
            const val = e.target.value;
            setFormData({...formData, appointmentDate: val});
            let date_day = getDayName(new Date(val))
            if (formData.doctorID !== ''){
                const schedules  = await axios.get(`${serverLink}patient-portal/appointment/list/${formData.doctorID}/${date_day}`)
                if (schedules.data.message === 'success'){
                    let doctor_schedule = schedules.data.data;
                    let doctor_slots = schedules.data.slots;
                    if (doctor_schedule.length > 0){
                        const schedule_day_id = doctor_schedule[0].daysID;
                        const schedule_slots = doctor_slots.filter(e=>e.daysID === schedule_day_id );
                        const sorted_slots = schedule_slots.sort((a, b) => {
                            const statusA = a.slot.toLowerCase();
                            const statusB = b.slot.toLowerCase();

                            if (statusA < statusB) return -1;
                            if (statusA > statusB) return 1;
                            return 0;
                        })
                        setScheduleSlots(sorted_slots)
                    }else{
                        setScheduleSlots([])
                        setFormData({...formData, appointmentTime: '', slotID: ''})
                        toast.error('The selected doctor have no schedule for the selected date')
                    }
                    setIsGettingSchedules(false)
                }
            }else{
                setIsGettingSchedules(false)
                setScheduleSlots([])
                toast.error('Please select a doctor first')
            }

        }

        if (e.target.name === 'appointmentTime'){
            let slot_time = e.target.getAttribute('data');
            let slot_id = e.target.getAttribute('data_id');
            setFormData({...formData, appointmentTime: slot_time, slotID: slot_id})
        }
    }

    const onChangeService = (e) => {
        const val = e.value;
        setFormData({
            ...formData,
            serviceID: val,
            serviceID2: e,
            itemName: e.label,
            appointmentAmount: e.amount,
        })
    }
    const onChangeServiceType = (e) => {
        const val = e.value;
        setServices(serviceList.filter(e=>e.service_id === val))
        setFormData({
            ...formData,
            consultationTypeID: val,
            consultationTypeID2: e,
            serviceID: '',
            serviceID2: '',
        })
    }

    const onChangeSpecialisation = (e) => {
        const val = e.value;
        setDoctor(doctorsList.filter(e=>e.specialisation === val))
        setFormData({
            ...formData,
            appointmentDepartment: val,
            appointmentDepartmentName: e.label,
            appointmentDepartment2: e,
            doctorID: '',
            doctorID2: '',
        })
    }

    const onChangeStaff = async (e) => {
        const val = e.value;
        setFormData({
            ...formData,
            doctorID: val,
            doctorName: e.label,
            doctorEmail: e.email_address,
            doctorID2: e,
        })
        const schedules  = await axios.get(`${serverLink}patient-portal/schedule/${val}`);
        setDoctorSchedules(schedules.data)
    }

    const onDescriptionChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }


    const onUserEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }


    const addAppointment =  (ref) => {
        setIsFormLoading(true)
        toast.info("please wait...");
        const regData = {
            ...formData,
            patientSerial: formData.patientSerial,
            paymentReference: ref.trxref,
            paymentAmount: formData.appointmentAmount,
            paymentStatus: "Paid",
            paymentFor: "Appointment",
            paymentType: "Credit",
            amountDue: formData.appointmentAmount,
            amountPaid: formData.appointmentAmount,
            walletAmount: formData.appointmentAmount,
            itemName: formData.itemName,
            paymentMethod: "Paystack",
        }

        axios.post(`${serverLink}login/patient/registration`, regData).then((res) => {
            if (res.data.message === "success") {
                const patientSerial = res.data.patientSerial;
                const appointmentData = {...regData, patientSerial: patientSerial, submittedByName: `${formData.firstName} ${formData.surname} ${formData.middleName}`, submittedBy: patientSerial}
                 axios.post(`${serverLink}patient-portal/appointment/add`, appointmentData).then(async (result) => {
                    if (result.data.message === "success") {
                        let item_id = result.data.appointment_id;
                        const sendData = {...appointmentData, itemID: item_id, appointmentID: item_id}
                         axios.post(`${serverLink}payment/post-payment`, sendData).then((res) => {
                            if (res.data.message === "success") {
                                toast.success("Appointment Booked Successfully");
                                setIsFormLoading(false);
                                resetFormData();
                                setScheduleSlots([])
                                setDoctorSchedules([])
                            } else {
                                setIsFormLoading(false);
                                toast.error("Something went wrong posting payment. Please try again!");
                            }
                        })
                    } else {
                        setIsFormLoading(false);
                        toast.error("Something went wrong adding appointment. Please try again!");
                    }
                })

            } else {
                setIsFormLoading(false);
                toast.error('Something went wrong, please try again.')
            }
        }).catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }

    const handlePayment = () => {
        if (formData.consultationTypeID.toString().trim() === ''){
            toast.error('Please select consultation type')
            return false
        }
        if (formData.serviceID.toString().trim() === ''){
            toast.error('Please select consultation')
            return false
        }
        if (formData.appointmentDepartment.toString().trim() === ''){
            toast.error('Please select specialisation')
            return false
        }
        if (formData.doctorID.toString().trim() === ''){
            toast.error('Please select doctor')
            return false
        }
        if (formData.appointmentDate.toString().trim() === ''){
            toast.error('Please select appointment date')
            return false
        }

        if (formData.firstName.toString().trim() === ''){
            toast.error('Please enter first name');
            return false;
        }

        if (formData.surname.toString().trim() === ''){
            toast.error('Please enter surname');
            return false;
        }

        if (formData.phoneNumber.toString().trim() === ''){
            toast.error('Please enter phone number');
            return false;
        }

        if (formData.emailAddress.toString().trim() === ''){
            toast.error('Please enter email address');
            return false;
        }

        initializePayment(onSuccess, onClose)
    }

    const onSuccess = (reference) => {
        addAppointment(reference)
    };

    const onClose = () => {
        console.log('closed')
    }

    const initializePayment = usePaystackPayment(config);

    const scrollTop = async () => {
        await window.scrollTo(0, 0);
    };


    return( IsLoading ?  <PageLoader/> :
            <>
                <PageName title="Book Appointment" subTitle="Book Appointment"/>
                <section id="services-4" className="wide-60 services-section division">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 section-title">

                                <h3 className="h3-md steelblue-color">Book Appointment</h3>

                                <p>Plan Your Appointment for a Convenient Time. </p>

                            </div>
                        </div>

                        <div className="row">
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Consultation Type</label>
                                <Select
                                    id="consultationTypeID"
                                    name="consultationTypeID"
                                    value={formData.consultationTypeID2}
                                    onChange={onChangeServiceType}
                                    options={serviceType}
                                    placeholder="Select Consultation Type"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Consultation</label>
                                <Select
                                    id="serviceID"
                                    name="serviceID"
                                    value={formData.serviceID2}
                                    onChange={onChangeService}
                                    options={services}
                                    placeholder="Select Consultation"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Specialisation</label>
                                <Select
                                    id="appointmentDepartment"
                                    name="appointmentDepartment"
                                    value={formData.appointmentDepartment2}
                                    onChange={onChangeSpecialisation}
                                    options={specialization}
                                    placeholder="Select Specialisation"
                                />
                            </div>


                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Doctor</label>
                                <Select
                                    id="doctorID"
                                    name="doctorID"
                                    value={formData.doctorID2}
                                    onChange={onChangeStaff}
                                    options={doctor}
                                    placeholder="Select Doctor"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-12">
                                {
                                    formData.doctorID !=='' ? <h3>Doctor Schedule</h3> :<></>
                                }
                                {
                                    formData.doctorID !=='' ?
                                    doctorSchedules.length > 0 ?
                                        doctorSchedules.map((item, index)=>{
                                            return(
                                                <div key={index} className="alert alert-info mb-2 col-md-12 d-flex justify-content-between align-items-center">
                                                    <div className="">
                                                        {item.dayName}
                                                    </div>
                                                    <div className="">
                                                        {item.startTime}  - {item.endTime}
                                                    </div>
                                                </div>
                                            )
                                        }) :
                                        <div className="alert alert-danger text-center mt-1">
                                            <h3>The selected doctor have no schedules.</h3>
                                        </div>
                                        : <></>
                                }
                            </div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Appointment Date</label>
                                <input
                                    type="date"
                                    name="appointmentDate"
                                    className="form-control"
                                    id="appointmentDate"
                                    value={formData.appointmentDate}
                                    onChange={onEdit}
                                    min={new Date().toISOString().split('T')[0]}
                                />
                            </div>
                            {
                                IsGettingSchedules ?
                                    <div className="mb-3 form-group col-md-12">
                                        <div className="alert alert-info">Please wait while the system is fetching the doctor's schedule.</div>
                                    </div>
                                    :
                                    <></>
                            }
                            {
                                IsGettingSchedules ? <></> :
                                    scheduleSlots.length > 0 ?
                                        formData.appointmentDepartmentName === 'Physician' ||  formData.appointmentDepartmentName === 'Endocrinologist' ||  formData.appointmentDepartmentName === 'Cardiologist' ||  formData.appointmentDepartmentName === 'Dentist' ?
                                            <></>
                                            :
                                        <div className="mb-3  col-md-12 ml-3">
                                            <div className="row">
                                                {
                                                    scheduleSlots.map((item, index)=> {
                                                        let color = item.status === 'available' ? 'badge-info' : 'badge-danger';
                                                        return (
                                                            <div key={index}  className="form-check col-md-3 col-lg-3 col-6 col-sm-6 mb-3">
                                                                <input className="form-check-input" type="radio" disabled={item.status !== 'available'} data={item.slot} data_id={item.slotID} name="appointmentTime" onChange={onEdit} id={`flexCheckIndeterminate${index}`}/>
                                                                <label className={`form-check-label text-white badge-pill ${color} pl-2 pr-2`} htmlFor={`flexCheckIndeterminate${index}`}>
                                                                    {item.slot}
                                                                </label>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div> : <></>
                            }

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Appointment Description</label>
                                <textarea
                                    rows={3}
                                    cols={3}
                                    name="appointmentDetail"
                                    className="form-control"
                                    id="appointmentDetail"
                                    value={formData.appointmentDetail}
                                    onChange={onDescriptionChange}
                                ></textarea>
                            </div>
                            {
                                formData.appointmentDate !=='' ?
                                    <>
                                        <div className="form-group mb-3 col-md-4">
                                            <label className="form-label">First Name</label>
                                            <input type="text" className="form-control form-control-lg"  value={formData.firstName} id="firstName" onChange={onUserEdit} />
                                        </div>
                                        <div className="form-group mb-3 col-md-4">
                                            <label className="form-label">Middle Name</label>
                                            <input type="text" className="form-control form-control-lg"  value={formData.middleName} id="middleName" onChange={onUserEdit}/>
                                        </div>
                                        <div className="form-group mb-3 col-md-4">
                                            <label className="form-label">Surname</label>
                                            <input type="text" className="form-control form-control-lg"  value={formData.surname} id="surname" onChange={onUserEdit}/>
                                        </div>
                                        <div className="form-group mb-3 col-md-6">
                                            <label className="form-label">Email Address</label>
                                            <input type="email" className="form-control form-control-lg"  value={formData.emailAddress} id="emailAddress" onChange={onUserEdit}/>
                                        </div>
                                        <div className="form-group mb-3 col-md-6">
                                            <label className="form-label">Phone Number</label>
                                            <input type="number" className="form-control form-control-lg"   value={formData.phoneNumber} id="phoneNumber" onChange={onUserEdit}/>
                                        </div>

                                    </>
                                    : <></>
                            }
                            <div className="mb-3 mt-3  col-md-12">
                                <button className="btn btn-primary bg-primary col-md-4 offset-sm-4" onClick={handlePayment}>Book Now</button>
                            </div>
                        </div>
                    </div>
                </section>
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        serviceList: state.ServiceDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnServiceDetails: (p) => {
            dispatch(setServiceDetails(p));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Appointment);