import React, {useEffect, useState} from "react";
import PageName from "../../component/common/page-name/page-name";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {projectPhone, serverLink} from "../../resources/url";

function ServiceDetails() {
    const navigate = useNavigate();
    const location = useLocation();
    const slug = useParams();
    const title =  slug.slug;
    const [isLoading, setIsLoading] = useState(true);
    const [serviceData, setServiceData] = useState({})
    const [serviceList, setServiceList] = useState([]);

    const getData = async () => {
        let service_id = title;
        await axios.get(`${serverLink}general/service/details`)
            .then((res) => {
                if (res.data.Service.length > 0){
                    const record = res.data.Service;
                    const service_data = res.data.ServiceData;
                    let details = record.filter(e=>e.Slug.toString()  === service_id.toString())
                    let associate_services = service_data.filter(e=>e.Slug.toString()  !== service_id.toString())
                    setServiceData(details[0]);
                    setServiceList(associate_services);
                }else{
                    navigate(`/our-services`)
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };
    const scrollTop = async () => {
        await window.scrollTo(0, 0);
    };

    useEffect(() => {
        if (slug.slug === "") {
            navigate(`/our-services`);
        }
        getData();
        scrollTop();
    }, []);
    return(
        <>
            <PageName title="Our Services & Facilities " subTitle="Our Services & Facilities"/>
            <div id="service-page" className="wide-60 service-page-section division text-left">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-8">
                            <div className="s2-page pr-30 mb-40">
                                <h4 className="h4-sm blue-color"
                                    style={{marginBottom: '-15px'}}>{serviceData.serviceName}</h4>
                                <div className="content-block-img text-center">
                                    <img className="img-fluid"
                                         src={`${serverLink}public/uploads/services_uploads/${serviceData.ImagePath}`}
                                         alt={serviceData.serviceName}/>
                                </div>
                                <p className="text-justify" style={{marginTop: '-15px'}}
                                   dangerouslySetInnerHTML={{__html: serviceData.serviceDescription}}/>
                                <Link to="/book-appointment" className="btn btn-blue blue-hover">Book an Appointment</Link>

                            </div>
                        </div>

                        <aside id="sidebar" className="col-lg-4">


                            <div id="txt-widget" className="sidebar-div mb-50">

                                <h5 className="h5-sm steelblue-color">The Heart Of Garkuwa Specialist Hospital</h5>

                                <div className="txt-widget-unit mb-15 clearfix d-flex align-items-center">

                                    <div className="txt-widget-avatar">
                                        <img src={require('../../images/cheif2.png')} alt="testimonial-avatar"/>
                                    </div>

                                    <div className="txt-widget-data">
                                        <h6 className="h5-md steelblue-color">Dr. A.B. Abubakar MBBS FWACP NPOM</h6>
                                        <span>Chief Medical Officer, Founder</span>
                                        {/*<p className="blue-color">1-800-1234-567</p>*/}
                                    </div>

                                </div>

                                <p className="p-sm text-justify">GARKUWA Specialist Hospital is one stop step hospital with various specialists always available to meet your health needs.
                                </p>

                                <hr/>

                            </div>

                            <div className="sidebar-table blue-table sidebar-div mb-50">
                                <h5 className="h5-md">Working Time</h5>
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <td>Mon – Fri</td>
                                        <td> - </td>
                                        <td className="text-right">24 hours a day</td>
                                    </tr>
                                    <tr className="last-tr">
                                        <td>Sat - Sun</td>
                                        <td>-</td>
                                        <td className="text-right">24 hours a day</td>
                                    </tr>
                                    </tbody>
                                </table>

                                <h5 className="h5-xs">Need a personal health plan?</h5>

                                <p className="p-sm text-white"><a style={{color: '#FFFFFF !important'}} href={`tel: ${projectPhone}`}>{projectPhone}</a> </p>

                            </div>

                        </aside>

                    </div>
                </div>
            </div>
        </>
    )
}
export default ServiceDetails;