import React, {useEffect} from "react";
import ContactSection from "../homepage/section/contact-section";
import PageName from "../../component/common/page-name/page-name";

function Contact() {
    const scrollTop = async () => {
        await window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, []);
    return(
        <>
            <PageName title="Contact Us" subTitle="Contact Us"/>
            <ContactSection/>
        </>
    )
}
export default Contact;