import {Link} from "react-router-dom";

function Header() {

    const dismissClick = () => {
        document.getElementById("wsnavtoggle").click();
    }


    return(
        <header id="header" className="header">

            <div className="wsmobileheader clearfix">
                <a id="wsnavtoggle" className="wsanimated-arrow"><span></span></a>
                <span className="smllogo"><img src={require('../../../images/logo.png')} width="140" height="40" alt="mobile-logo"/></span>
                <a href="tel:123456789" className="callusbtn"><i className="fas fa-phone"></i></a>
            </div>

            <div className="headtoppart bg-blue clearfix">
                <div className="headerwp clearfix">

                    <div className="headertopleft">
                        <div className="address clearfix"><span><i className="fas fa-map-marker-alt"></i>1A Sultan / Isa Kaita Road , Ungwa Rimi , Kaduna </span> <a href="tel:0803308809" className="callusbtn"><i className="fas fa-phone"></i>+234-803308809</a>
                        </div>
                    </div>

                    <div className="headertopright">
                        <a className="googleicon" title="Google" href="#"><i className="fab fa-google"></i> <span className="mobiletext02">Google</span></a>
                        <a className="linkedinicon" title="Linkedin" href="#"><i className="fab fa-linkedin-in"></i> <span className="mobiletext02">Linkedin</span></a>
                        <a className="twittericon" title="Twitter" href="#"><i className="fab fa-twitter"></i> <span className="mobiletext02">Twitter</span></a>
                        <a className="facebookicon" title="Facebook" href="#"><i className="fab fa-facebook-f"></i> <span className="mobiletext02">Facebook</span></a>
                    </div>
                </div>
            </div>

            <div className="wsmainfull menu clearfix">
                <div className="wsmainwp clearfix">

                    <div className="desktoplogo"><Link to="/"><img src={require('../../../images/logo.png')}  width="180" height="50" alt="header-logo"/></Link></div>

                    <nav className="wsmenu clearfix">
                        <ul className="wsmenu-list">
                            <li className="nl-simple" aria-haspopup="true"><a href="/" onClick={()=>{ dismissClick() }}>Home</a></li>
                            <li className="nl-simple" aria-haspopup="true"><Link to="/who-we-are" onClick={()=>{ dismissClick() }}>Who We Are</Link></li>
                            <li className="nl-simple" aria-haspopup="true"><Link to="/our-services" onClick={()=>{ dismissClick() }}>Our Services</Link></li>
                            <li aria-haspopup="true"><a>Projects <span className="wsarrow"></span></a>
                                <ul className="sub-menu">
                                    <li aria-haspopup="true"><Link to="/project"  onClick={()=>{ dismissClick() }}>Project</Link></li>
                                    <li aria-haspopup="true"><Link to="/work-with-us" onClick={()=>{ dismissClick() }}>Work & Volunteer with us</Link></li>
                                </ul>
                            </li>
                            <li aria-haspopup="true"><a>Media <span className="wsarrow"></span></a>
                                <ul className="sub-menu">
                                    <li aria-haspopup="true"><Link to="/news" onClick={()=>{ dismissClick() }}>News</Link></li>
                                    <li aria-haspopup="true"><a href="/gallery" onClick={()=>{ dismissClick() }}>Gallery</a></li>
                                </ul>
                            </li>
                            <li className="nl-simple header-btn" aria-haspopup="true"><Link to="/book-appointment" onClick={()=>{ dismissClick() }}>Book Appointment</Link></li>


                        </ul>
                    </nav>

                </div>
            </div>


        </header>
    )
}
export default Header;