import {formatDateAndTime, serverLink} from "../../../resources/url";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {connect} from "react-redux";

function NewsSection(props) {
    const [newsData, setNewsData] = useState([...props.newsList])
    return(
        <section id="blog-1" className="wide-60 blog-section division">
            <div className="container">

                <div className="row">
                    <div className="col-lg-10 offset-lg-1 section-title">

                        <h3 className="h3-md steelblue-color">Recent Articles and News</h3>

                        <p>
                            Bringing You the Latest Health Trends, Research Findings, and Hospital Developments to Keep You Well-Informed
                        </p>

                    </div>
                </div>


                <div className="row text-left">
                    {
                        newsData.length > 0 ?
                            newsData.slice(0,3).map((item, index)=>{
                                const slug = item.title.trim().replace(/\s+/g, '-').toLowerCase();
                                const date = formatDateAndTime(item.submittedOn, 'date');
                                return (
                                    <div key={index} className="col-lg-4">
                                        <div className="blog-post wow fadeInUp" data-wow-delay="0.8s">
                                            <div className="blog-post-img">
                                                <Link to={`/news/${slug}`}>
                                                    <img className="img-fluid" src={`${serverLink}public/uploads/news_uploads/${item.image}`} alt={item.title} />
                                                </Link>
                                            </div>

                                            <div className="blog-post-txt">

                                                <h5 className="h5-sm steelblue-color"><Link to={`/news/${slug}`}>{item.title?.slice(0, 75) + (item.title?.length > 75 ? "..." : "")}</Link></h5>
                                                <div className="d-flex justify-content-between mt-2 mb-2">
                                                    <span className="steelblue-color">{date}</span>
                                                    <span className="steelblue-color">{item.submittedBy}</span>
                                                </div>

                                                <p className="text-justify" dangerouslySetInnerHTML={{__html: item.description?.slice(0, 145) + (item.description?.length > 145 ? "..." : "")}} />

                                            </div>

                                        </div>
                                    </div>
                                )
                            }) : <div className="col-md-12 text-center"><h3>No Record Found</h3></div>
                    }
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="all-posts-btn mb-40 wow fadeInUp" data-wow-delay="1s">
                            <Link to="/news" className="btn btn-blue blue-hover">Read More Posts</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NewsSection;