import PageRoutes from "./pageroutes/pageroutes";
import {
  setDepartmentListDetails, setGalleryCategoryDetails,
  setGalleryDetails,
  setHomeSliderDetails,
  setNewsDetails, setServiceDetails,
} from "./actions/setactiondetails";
import { connect } from "react-redux";
import axios from "axios";
import { serverLink } from "./resources/url";
import { browserName, isMobile } from "react-device-detect";
import {useEffect, useState} from "react";
import PageLoader from "./component/common/loader/page-loader";
function App(props) {
  const [isLoading, setIsLoading] = useState(
      !!(
          props.newsList > 0 &&
          props.sliderList > 0 &&
          props.serviceList > 0
      )
  );

  const getLocationData = async () => {
    await axios
        .get("https://geolocation-db.com/json/")
        .then(async (result) => {
          await getVisitorData(result.data);
        })
        .catch((e) => {
          console.log("Error Getting User Details");
        });
  };


  const getVisitorData = async (locData) => {
    let sendData = {
      IPAddress: locData.IPv4,
      Country: locData.country_name,
      lat: locData.latitude,
      lng: locData.longitude,
      IsMobileRequest: isMobile ? "1" : "0",
      UserAgent: browserName,
    };
    await axios
        .post(`${serverLink}general/website/visit-hit`, sendData)
        .then((data) => {})
        .catch((err) => {
          console.log("Error Adding User Details");
        });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`${serverLink}general/website/content`);
        props.setOnNewsDetails(result.data.News);
        props.setOnSliderDetails(result.data.Slider);
        props.setOnGalleryDetails(result.data.Gallery);
        props.setOnGalleryCategoryDetails(result.data.Category);
        props.setOnServiceDetails(result.data.Service);
      } catch (error) {
        console.log("Error fetching content", error);
      } finally {
          setIsLoading(false);
      }
    };

    fetchData().then((r) => {});
  }, []);

  useEffect(() => {
    // getData().then((r) => {});
    getLocationData().then((r) => {}).finally(()=>{});
  }, []);
  return (
    <div className="App"> {isLoading ? <PageLoader/> :  <PageRoutes />} </div>
  );
}

const mapStateToProps = (state) => {
  return {
    departmentList: state.DepartmentListDetails,
    newsList: state.NewsDetails,
    sliderList: state.SliderDetails,
    serviceList: state.ServiceDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnDepartmentListDetails: (p) => {
      dispatch(setDepartmentListDetails(p));
    },
    setOnNewsDetails: (p) => {
      dispatch(setNewsDetails(p));
    },
    setOnSliderDetails: (p) => {
      dispatch(setHomeSliderDetails(p));
    },
    setOnGalleryDetails: (p) => {
      dispatch(setGalleryDetails(p));
    },
    setOnGalleryCategoryDetails: (p) => {
      dispatch(setGalleryCategoryDetails(p));
    },
    setOnServiceDetails: (p) => {
      dispatch(setServiceDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
