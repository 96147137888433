import React, {useEffect, useState} from "react";
import PageName from "../../component/common/page-name/page-name";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {serverLink} from "../../resources/url";

function Gallery(props) {
    const [loading, setLoading] = useState(props.Gallery.length < 1);
    const [gallery_data, setGallery_data] = useState([...props.Gallery])
    const [gallery_category, setGallery_category] = useState([...props.GalleryCategory])

    const scrollTop = async () => {
        await window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, []);
    return(
        <>
            <PageName title="Our Gallery" subTitle="Our Gallery"/>
            <div id="gallery-3" className="gallery-section division">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="gallery-filter mb-60">
                                <button data-filter="*" className="is-checked">All</button>
                                {
                                    gallery_category.length > 0 && gallery_category.map((item, index) => {
                                        const slug = item.categoryName.trim().replace(/\s+/g, '-').toLowerCase();
                                        return (
                                            <button key={index} data-filter={`.${slug}`}>{item.categoryName}</button>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="masonry-wrap grid-loaded">
                                {
                                    gallery_category.length > 0 && gallery_category.map((item, index) => {
                                        const slug = item.categoryName.trim().replace(/\s+/g, '-').toLowerCase();
                                        return (
                                            <div key={index}  className={`gallery-item ${slug}`}>
                                                {
                                                    gallery_data.filter(e => e.categoryID === item.categoryID).length > 0 && gallery_data.filter(e => e.categoryID === item.categoryID).map((element, key) => {
                                                            return (
                                                                <div key={key} className="hover-overlay">
                                                                    <img className="img-fluid"
                                                                         src={`${serverLink}public/uploads/gallery_uploads/${element.image}`}
                                                                         alt={element.title}/>
                                                                    <div className="item-overlay"></div>

                                                                    <div className="image-zoom">
                                                                        <a className="image-link"
                                                                           href={`${serverLink}public/uploads/gallery_uploads/${element.image}`}
                                                                           title={element.title}><i
                                                                            className="fas fa-search-plus"></i></a>
                                                                    </div>
                                                                </div>
                                                    )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        Gallery: state.GalleryDetails,
        GalleryCategory: state.GalleryCategoryDetails,
    };
};

export default connect(mapStateToProps, null)(Gallery);