import React, {useEffect, useState} from "react";
import PageName from "../../component/common/page-name/page-name";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {formatDateAndTime, serverLink} from "../../resources/url";
import axios from "axios";
import PageLoader from "../../component/common/loader/page-loader";

function NewsDetails() {
    const navigate = useNavigate();
    const location = useLocation();
    const slug = useParams();
    const title =  slug.slug;
    const [isLoading, setIsLoading] = useState(true);
    const [newsData, setNewsData] = useState([])
    const [newsList, setNewsList] = useState([]);

    const getData = async () => {
        let new_id = title;
        await axios.get(`${serverLink}news/list/active`)
            .then((res) => {
                if (res.data.length > 0){
                    const record = res.data;
                    let details = record.filter(e=>e.Slug.toString()  === new_id.toString())
                    let other_news = record.filter(e=>e.Slug.toString()  !== new_id.toString())
                    setNewsData(details);
                    setNewsList(other_news);
                    updateViewCount(details[0].newsID)
                }else{
                    navigate(`/news`)
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const updateViewCount = async (id) => {
        const sendData = {NewsID: id};
        await axios.patch(`${serverLink}news/update/view-count`, sendData)
            .then((result) => {}).catch((err) => {console.log("NETWORK ERROR");});
    };

    const scrollTop = async () => {
        await window.scrollTo(0, 0);
    };

    useEffect(() => {
        if (slug.slug === "") {
            navigate("/news");
        }
        getData();
        scrollTop();
    }, [location]);
    return(isLoading ? <PageLoader/> :
        <>
            <PageName title="News Details" subTitle="Recent Articles and News"/>
            <div id="single-blog-page" className="wide-100 blog-page-section division text-left">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-8">
                            <div className="single-blog-post pr-30">


                                <div className="blog-post-img mb-40">
                                    <img className="img-fluid" src={`${serverLink}public/uploads/news_uploads/${newsData[0].image}`} alt={newsData[0].title} />
                                </div>

                                <div className="sblog-post-txt">

                                    <h5 className="h5-lg steelblue-color  text-justify">{newsData[0].title}</h5>

                                    <span> {formatDateAndTime(newsData[0].submittedOn, 'date_and_time')} by <span> {newsData[0].submittedBy}</span></span>

                                    <p className="mt-30 text-justify" dangerouslySetInnerHTML={{__html: newsData[0].description}} />

                                    <div className="post-share-links">
                                        <div className="post-share-list">
                                            <ul className="share-social-icons clearfix">
                                                <li><a href="#" className="share-ico ico-like"><i className="far fa-thumbs-up"></i> Like</a></li>
                                                <li><a href="#" className="share-ico ico-facebook"><i className="fab fa-facebook-f"></i> Share</a></li>
                                                <li><a href="#" className="share-ico ico-twitter"><i className="fab fa-twitter"></i> Tweet</a></li>
                                            </ul>
                                        </div>

                                    </div>


                                </div>


                            </div>
                        </div>


                        <aside id="sidebar" className="col-lg-4">
                            <div className="popular-posts sidebar-div mb-50">

                                <h5 className="h5-sm steelblue-color">Related Posts</h5>

                                <ul className="popular-posts">
                                    {
                                        newsList.length > 0 ? newsList.map((item, index)=> {
                                            const date = formatDateAndTime(item.submittedOn, 'date');
                                            return(
                                                <li key={index} className="clearfix d-flex align-items-center">

                                                    <img className="img-fluid" width={150} height={100} src={`${serverLink}public/uploads/news_uploads/${item.image}`} alt={item.title} />

                                                    <div className="post-summary">
                                                        <Link to={`/news/${item.Slug}`}>{item.title?.slice(0, 55) + (item.title?.length > 55 ? "..." : "")}</Link>
                                                        <p>{date}</p>
                                                    </div>

                                                </li>
                                            )
                                        }) : <div className="text-center p-2"><h4>No result found!</h4></div>
                                    }

                                </ul>

                            </div>



                        </aside>


                    </div>
                </div>
            </div>
        </>
    )
}
export default NewsDetails;