import {useEffect} from "react";

function AboutSection() {
    return (
        <section id="about-5" className="bg-lightgrey pt-100 about-section division">
            <div className="container">
                <div className="row d-flex align-items-center">

                    <div className="col-lg-6">
                        <div className="about-img text-center wow fadeInUp" data-wow-delay="0.6s">
                            <img className="img-fluid" src={require('../../../images/about.png')} alt="about-image"/>
                        </div>
                    </div>


                    <div className="col-lg-6">
                        <div className="txt-block pc-30 wow fadeInUp" data-wow-delay="0.4s">

                            <span className="section-id blue-color">Welcome to  GARKUWA SPECIALIST HOSPITAL</span>

                            <h3 className="h3-md steelblue-color">keeping you healthy is our business</h3>

                            <p className="text-justify">Garkuwa Specialist Hospital was established in 1990 with a view to providing standard
                                specialist medical services in Kaduna and its environs. Over the years, the hospital
                                became the pioneer in the provision of multi-disciplinary specialist services among the
                                private health facilities in Kaduna, Nigeria.
                            </p>

                            <div className="singnature mt-35">
                                <span><b className="fw-bold text-dark">Dr. A.B. Abubakar MBBS FWACP NPOM</b></span>
                                <p className="p-small mb-15"> Chief Medical Officer, Founder</p>

                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </section>
    )
}

export default AboutSection;