import {Link} from "react-router-dom";
import {projectName} from "../../../resources/url";

function Footer() {
    return(
        <footer id="footer-3" className="wide-40 footer division text-left">
            <div className="container">

                <div className="row">

                    <div className="col-md-6 col-lg-4">
                        <div className="footer-info mb-40">

                            <img src={require('../../../images/logo.png')}  width="230" height="60" alt="footer-logo"/>

                                <p className="p-sm mt-20">GARKUWA Specialist Hospital is one stop step hospital with various specialists always available to meet your health needs.
                                </p>

                                <div className="footer-socials-links mt-20">
                                    <ul className="foo-socials text-center clearfix">

                                        <li><a href="facebook.com" className="ico-facebook"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="x.com" className="ico-twitter"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="instagram.com" className="ico-instagram"><i className="fab fa-instagram"></i></a></li>
                                        <li><a href="linkedin.com" className="ico-linkedin"><i className="fab fa-linkedin"></i></a></li>
                                    </ul>
                                </div>

                        </div>
                    </div>


                    <div className="col-md-6 col-lg-3 offset-lg-1">
                        <div className="footer-box mb-40">

                            <h5 className="h5-xs">Our Location</h5>

                            <p>1A Sultan \ Isa Kaita Road ,</p>
                            <p>Ungwa Rimi , Kaduna</p>
                            <p>Nigeria </p>

                            <p className="foo-email mt-20">E: <a href="mailto:garkuwahospital@yahoo.com">garkuwahospital@yahoo.com</a></p>

                            <p>P: +234-803308809</p>

                        </div>
                    </div>

                    <div className="col-md-6 col-lg-2">
                        <div className="footer-links mb-40">

                            <h5 className="h5-xs">About Us</h5>

                            <ul className="foo-links clearfix">
                                <li><Link to="/who-we-are">About Clinic</Link></li>
                                <li><Link to="/our-services">Our Services</Link></li>
                                <li><a href="/gallery">Media & Galley</a></li>
                                <li><Link to="/news">Our Blog</Link></li>
                            </ul>

                        </div>
                    </div>

                    <div className="col-md-6 col-lg-2">
                        <div className="footer-links mb-40">

                            <h5 className="h5-xs">Discover</h5>

                            <ul className="clearfix">
                                <li><Link to="/project">Projects</Link></li>
                                <li><Link to="/work-with-us">Work & volunteer with us</Link></li>
                                <li><Link to="#">Terms & Privacy</Link></li>
                                <li><Link to="#">FAQs</Link></li>
                            </ul>

                        </div>
                    </div>


                </div>


                <div className="bottom-footer">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="footer-copyright">&copy; 2024 <span>{projectName}</span>. All Rights Reserved</p>
                        </div>
                    </div>
                </div>


            </div>
        </footer>
    )
}
export default Footer;