import React, {useEffect, useState} from "react";
import PageName from "../../component/common/page-name/page-name";
import {Link} from "react-router-dom";
import {setServiceDetails} from "../../actions/setactiondetails";
import {connect} from "react-redux";
import PageLoader from "../../component/common/loader/page-loader";
import axios from "axios";
import {serverLink} from "../../resources/url";

function Services(props) {
    const [loading, setLoading] = useState(props.serviceList.length < 1);
    const [serviceData, setServiceData] = useState([...props.serviceList])
    const getData = async () => {
        await axios
            .get(`${serverLink}general/services/list`)
            .then((result) => {
                const data = result.data;
                setServiceData(data);
                props.setOnServiceDetails(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    const scrollTop = async () => {
        await window.scrollTo(0, 0);
    };

    useEffect(() => {
        getData();
        scrollTop();
    }, []);

    return( loading ?  <PageLoader/> :
            <>
            <PageName title="Our Services & Facilities " subTitle="Our Services & Facilities"/>
            <section id="services-4" className="wide-60 services-section division">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 section-title">

                            <h3 className="h3-md steelblue-color">Our Services & Facilities</h3>

                            <p>GARKUWA Specialist hospital has both in & out patient facilities including the following department and units. </p>

                        </div>
                    </div>

                    <div className="row text-left">
                        {
                            serviceData.length > 0 ?
                                serviceData.slice(0,6).map((item, index) => {
                                    return (
                                        <div key={index} className="col-md-6 col-lg-3">
                                            <div className="sbox-4 wow fadeInUp" data-wow-delay="0.4s">
                                                <Link to={`/service/${item.Slug}`}>
                                                    <img className="img-fluid"
                                                         src={`${serverLink}public/uploads/services_uploads/${item.ImagePath}`}
                                                         alt={item.serviceName}
                                                         style={{height: '200px'}}
                                                    />
                                                </Link>
                                                <div className="sbox-4-txt">
                                                    <h5 className="h5-md steelblue-color"><Link to={`/service/${item.Slug}`}>{item.serviceName}</Link></h5>
                                                    <p className=""  dangerouslySetInnerHTML={{__html: item.serviceDescription?.slice(0, 145) + (item.serviceDescription?.length > 145 ? "..." : "")}} />
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })  : <div className="col-md-12 text-center"><h3>No Record Found</h3></div>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        serviceList: state.ServiceDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnServiceDetails: (p) => {
            dispatch(setServiceDetails(p));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Services);