import {useEffect, useState} from "react";

function ServiceSection(props) {
    const [serviceData, setServiceData] = useState([...props.serviceList])

    return(
        <section id="services-2" className="wide-70 services-section division">
            <div className="container">


                <div className="row">
                    <div className="col-lg-10 offset-lg-1 section-title">

                        <h3 className="h3-md steelblue-color">Our Services & Facilities </h3>

                        <p>Dedicated to Your Health: Explore Our Comprehensive Services</p>

                    </div>
                </div>


                <div className="row">
                    {
                        serviceData.length > 0 ?
                            serviceData.slice(0,4).map((item, index) => {
                                return (
                                    <div key={index} className="col-sm-6 col-lg-3">
                                        <div className="sbox-2 wow fadeInUp" data-wow-delay="0.4s">
                                            <a href={`/service/${item.Slug}`}>

                                                <div className="sbox-2-icon icon-xl">
                                                    <span className={item.Icon}></span>
                                                </div>

                                                <h5 className="h5-sm sbox-2-title steelblue-color">{item.serviceName}</h5>

                                            </a>
                                        </div>
                                    </div>
                                )
                            }) : <></>
                    }
                </div>	   


            </div>	   
        </section>
    )
}
export default ServiceSection;