function CountSection() {
    return(
        <div id="statistic-2" className="bg-lightgrey statistic-section division">
            <div className="container">
                <div className="row">


                    <div className="col-md-6 col-lg-3">
                        <div className="statistic-block icon-sm wow fadeInUp" data-wow-delay="0.4s">

                            <span className="flaticon-062-cardiogram-3 blue-color"></span>

                            <h5 className="statistic-number">82,<span className="count-element">236</span></h5>
                            <p className="txt-400">Happy Patients</p>

                        </div>
                    </div>


                    <div className="col-md-6 col-lg-3">
                        <div className="statistic-block icon-sm wow fadeInUp" data-wow-delay="0.6s">

                            <span className="flaticon-137-doctor blue-color"></span>

                            <h5 className="statistic-number"><span className="count-element">20</span></h5>
                            <p className="txt-400">Qualified Doctors</p>

                        </div>
                    </div>


                    <div className="col-md-6 col-lg-3">
                        <div className="statistic-block icon-sm wow fadeInUp" data-wow-delay="0.8s">

                            <span className="flaticon-065-hospital-bed blue-color"></span>

                            <h5 className="statistic-number"><span className="count-element">54</span></h5>
                            <p className="txt-400">Clinic Rooms</p>

                        </div>
                    </div>


                    <div className="col-md-6 col-lg-3">
                        <div className="statistic-block icon-sm wow fadeInUp" data-wow-delay="1s">

                            <span className="flaticon-040-placeholder blue-color"></span>

                            <h5 className="statistic-number"><span className="count-element">8</span></h5>
                            <p className="txt-400">Local Partners</p>

                        </div>
                    </div>


                </div>  
            </div>	
        </div>
    )
}
export default CountSection;