function ContactSection() {
    return(
        <section id="banner-8" className="bg-fixed banner-section division">
            <div className="container white-color">
                <div className="row d-flex align-items-center">


                    <div className="col-lg-8 offset-lg-2">
                        <div className="banner-txt icon-lg text-center">

                            <span className="flaticon-072-hospital-5"></span>

                            <h3 className="h3-sm">Take the First Step to Help</h3>

                            <h4 className="h4-lg">Call Garkuwa Specialist Hospital Now</h4>
                            <h2 className="h2-lg">+234803308809</h2>

                        </div>
                    </div>


                </div>
            </div>
        </section>
    )
}
export default ContactSection;