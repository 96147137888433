import {serverLink} from "../../../resources/url";
import {Link} from "react-router-dom";
function Slider(props) {

    return(
        <section id="hero-2" className="hero-section division">
            <div className="slider blue-nav">
                <ul className="slides">
                    {
                        props.sliderList.map((item, index) => {
                                return (
                                <li id={`slide-${index}`} key={index}>
                                    <img src={`${serverLink}public/uploads/sliders_uploads/${item.image}`} alt="slide-background"/>
                                    <div className="caption d-flex align-items-center left-align">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-9 col-lg-7">
                                                    <div className="caption-txt">
                                                        <h2 className="steelblue-color"><span className="blue-color">{item.title}</span></h2>
                                                        {/*<h2 className="steelblue-color">We will help you <span className="blue-color">to become healthy</span></h2>*/}
                                                        <p className="p-md">{item.caption}</p>

                                                        <Link to={item.btnLink} className="btn btn-blue blue-hover">{item.btnName}</Link>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </li>
                            )
                        }
                        )
                    }
                </ul>
            </div>


        </section>
    )
}
export default Slider;