import React, {useEffect, useState} from "react";
import PageName from "../../component/common/page-name/page-name";
import {Link} from "react-router-dom";
import axios from "axios";
import {formatDateAndTime, serverLink} from "../../resources/url";
import {setNewsDetails} from "../../actions/setactiondetails";
import {connect} from "react-redux";

function News(props) {
    const [loading, setLoading] = useState(props.newsList.length < 1);
    const [newsData, setNewsData] = useState([...props.newsList])
    const getData = async () => {
        await axios
            .get(`${serverLink}news/list/active`)
            .then((result) => {
                const data = result.data;
                setNewsData(data);
                props.setOnNewsDetails(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    const scrollTop = async () => {
        await window.scrollTo(0, 0);
    };

    useEffect(() => {
        getData();
        scrollTop();
    }, []);

    return(
        <>
            <PageName title="News" subTitle="Recent Articles and News"/>
            <section id="blog-1" className="wide-60 blog-section division">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 section-title">

                            <h3 className="h3-md steelblue-color">Recent Articles and News</h3>

                            <p>
                                Bringing You the Latest Health Trends, Research Findings, and Hospital Developments to Keep You Well-Informed
                            </p>

                        </div>
                    </div>


                    <div className="row text-left">
                        {
                            newsData.length > 0 ?
                                newsData.map((item, index)=>{
                                    const slug = item.title.trim().replace(/\s+/g, '-').toLowerCase();
                                    const date = formatDateAndTime(item.submittedOn, 'date');
                                    return (
                                        <div key={index} className="col-lg-4">
                                            <div className="blog-post wow fadeInUp" data-wow-delay="0.8s">
                                                <div className="blog-post-img">
                                                    <Link to={`/news/${slug}`}>
                                                        <img className="img-fluid" src={`${serverLink}public/uploads/news_uploads/${item.image}`} alt={item.title} />
                                                    </Link>
                                                </div>

                                                <div className="blog-post-txt">

                                                    <h5 className="h5-sm steelblue-color"><Link to={`/news/${slug}`}>{item.title?.slice(0, 75) + (item.title?.length > 75 ? "..." : "")}</Link></h5>
                                                    <div className="d-flex justify-content-between mt-2 mb-2">
                                                        <span className="steelblue-color">{date}</span>
                                                        <span className="steelblue-color">{item.submittedBy}</span>
                                                    </div>

                                                    <p className="text-justify" dangerouslySetInnerHTML={{__html: item.description?.slice(0, 145) + (item.description?.length > 145 ? "..." : "")}} />

                                                </div>

                                            </div>
                                        </div>
                                    )
                                }) : <div className="col-md-12 text-center"><h3>No Record Found</h3></div>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        newsList: state.NewsDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnNewsDetails: (p) => {
            dispatch(setNewsDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(News);