import {Link} from "react-router-dom";

function ServiceTabSection() {
    return (
        <section id="tabs-1" className="wide-100 tabs-section division">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">


                        <div id="tabs-nav" className="list-group text-center">
                            <ul className="nav nav-pills" id="pills-tab" role="tablist">

                                <li className="nav-item icon-xs">
                                    <a className="nav-link active" id="tab1-list" data-toggle="pill" href="#tab-1"
                                       role="tab" aria-controls="tab-1" aria-selected="true">
                                        <span className="flaticon-083-stethoscope"></span> Pediatrics
                                    </a>
                                </li>

                                <li className="nav-item icon-xs">
                                    <a className="nav-link" id="tab2-list" data-toggle="pill" href="#tab-2" role="tab"
                                       aria-controls="tab-2" aria-selected="false">
                                        <span className="flaticon-005-blood-donation-3"></span> Laboratory
                                    </a>
                                </li>

                                <li className="nav-item icon-xs">
                                    <a className="nav-link" id="tab3-list" data-toggle="pill" href="#tab-3" role="tab"
                                       aria-controls="tab-3" aria-selected="false">
                                        <span className="flaticon-031-scanner"></span> General Surgery
                                    </a>
                                </li>

                                <li className="nav-item icon-xs">
                                    <a className="nav-link" id="tab4-list" data-toggle="pill" href="#tab-4" role="tab"
                                       aria-controls="tab-4" aria-selected="false">
                                        <span className="flaticon-109-injection"></span> Pharmacy
                                    </a>
                                </li>

                            </ul>

                        </div>


                        <div className="tab-content text-left" id="pills-tabContent">

                            <div className="tab-pane fade show active" id="tab-1" role="tabpanel"
                                 aria-labelledby="tab1-list">
                                <div className="row d-flex align-items-center">

                                    <div className="col-lg-6">
                                        <div className="tab-img">
                                            <img className="img-fluid" src={require('../../../images/baby.jpg')}
                                                 alt="tab-image"/>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="txt-block pc-30">

                                            <h3 className="h3-md steelblue-color">Pediatrics</h3>

                                            <p className="mb-30 text-justify">Welcome to Garkuwa Specialist Hospital's
                                                Paediatric Care service, where we prioritize the health and well-being
                                                of our youngest
                                                patients. Our state-of-the-art facility is dedicated to providing
                                                world-class medical care tailored specifically for children and
                                                adolescents. Led by a team of highly skilled paediatricians, nurses, and
                                                support staff, we are committed to delivering exceptional healthcare
                                                with a compassionate touch.
                                            </p>


                                            <Link to="/our-services" className="btn btn-blue blue-hover mt-30">View
                                                More Details</Link>

                                        </div>
                                    </div>


                                </div>
                            </div>


                            <div className="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="tab2-list">
                                <div className="row d-flex align-items-center">


                                    <div className="col-lg-6">
                                        <div className="tab-imgs">
                                            <img className="img-fluid" src="images/hematology_700x700.jpg"
                                                 alt="tab-image"/>
                                        </div>
                                    </div>


                                    <div className="col-lg-6">
                                        <div className="txt-block pc-30">

                                            <h3 className="h3-md steelblue-color">Laboratory</h3>

                                            <p className="mb-30 text-justify">Welcome to Garkuwa Specialist Hospital's
                                                premier laboratory services, where we prioritize accuracy, efficiency,
                                                and patient care. Our team of highly skilled and experienced laboratory
                                                professionals is dedicated to providing precise and timely diagnostic
                                                results using state-of-the-art technology and best practices.
                                            </p>

                                            <p className="mb-30 text-justify">At Garkuwa Specialist Hospital, we
                                                understand the critical role accurate diagnostics play in effective
                                                healthcare. Whether you require routine blood tests, specialized
                                                screenings, or urgent diagnostic services, our comprehensive laboratory
                                                services are designed to meet all your medical testing needs.
                                            </p>

                                            <Link to="/our-services" className="btn btn-blue blue-hover mt-30">View
                                                More Details</Link>

                                        </div>
                                    </div>


                                </div>
                            </div>


                            <div className="tab-pane fade" id="tab-3" role="tabpanel" aria-labelledby="tab3-list">
                                <div className="row d-flex align-items-center">


                                    <div className="col-lg-6">
                                        <div className="tab-img">
                                            <img className="img-fluid" src="images/contact.JPG" alt="tab-image"/>
                                        </div>
                                    </div>


                                    <div className="col-lg-6">
                                        <div className="txt-block pc-30">

                                            <h3 className="h3-md steelblue-color">General Surgery</h3>

                                            <p className="mb-30 text-justify">Welcome to Garkuwa Specialist Hospital's
                                                exceptional general surgery services, where we prioritize your health
                                                and recovery. Our team of highly skilled and compassionate surgeons is
                                                dedicated to providing top-quality surgical care using state-of-the-art
                                                technology and the latest techniques.
                                            </p>



                                            <Link to="/our-services" className="btn btn-blue blue-hover mt-30">View
                                                More Details</Link>

                                        </div>
                                    </div>


                                </div>
                            </div>


                            <div className="tab-pane fade" id="tab-4" role="tabpanel" aria-labelledby="tab4-list">
                                <div className="row d-flex align-items-center">


                                    <div className="col-lg-6">
                                        <div className="tab-img">
                                            <img className="img-fluid" src="images/pharmacy.JPG" alt="tab-image"/>
                                        </div>
                                    </div>


                                    <div className="col-lg-6">
                                        <div className="txt-block pc-30">

                                            <h3 className="h3-md steelblue-color">Pharmacy</h3>

                                            <p className="mb-30 text-justify">
                                                Welcome to Garkuwa Specialist Hospital's outstanding pharmacy services,
                                                where your health and well-being are our top priorities. Our team of
                                                highly trained and compassionate pharmacists is dedicated to providing
                                                safe, effective, and personalized medication management using the latest
                                                technology and best practices.
                                            </p>
                                            <Link to="/our-services" className="btn btn-blue blue-hover mt-30">View More Details</Link>
                                        </div>
                                    </div>


                                </div>
                            </div>


                        </div>


                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceTabSection;